import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

import Top from '../components/menu/top'

const randomGenerator = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        allSoloJson {
          edges {
            node {
              id
              item {
                title
                image {
                  childImageSharp {
                    fluid(maxWidth: 1280, quality: 65) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { allSoloJson } = data
      const { edges } = allSoloJson
      const randomPosition = randomGenerator(0, edges.length - 1)
      const randomizedImage = edges[randomPosition].node
      return (
        <div
          className="notfound"
          style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}
        >
          <div style={{ position: 'absolute', opacity: '0.90' }}>
            <Top />
          </div>
          <div
            style={{
              position: 'absolute',
              width: '100vw',
              textAlign: 'center',
              lineHeight: '1',
              bottom: '10vh',
              color: '#FFF',
              opacity: '0.90',
            }}
          >
            <div
              style={{
                fontSize: '30vw',
                fontFamily: 'Public Sans Bold, Arial, Helvetica, sans-serif',
              }}
            >
              404
            </div>
            <div
              style={{
                fontSize: '4vw',
                fontFamily: 'Public Sans Bold, Arial, Helvetica, sans-serif',
              }}
            >
              {' '}
              page not found{' '}
            </div>
          </div>
          <Img
            className="img404"
            style={{ height: '100vh', width: '100vw', zIndex: '-5' }}
            fluid={randomizedImage.item.image.childImageSharp.fluid}
          />
        </div>
      )
    }}
  />
)
